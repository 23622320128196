<template>
  <v-form>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Formations</h3>
          <p>Saisir les informations relatives aux formations</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <h3 v-if="courses.length ===0">
            Pas de formation associée
          </h3>
          <v-row
            v-for="(course, index) of courses"
            :key="index"
          >
            <v-col
              cols="12"
            >
              <h3>{{ course.course.name }} ({{ course.course.duration /60 }} h)</h3>
            </v-col>
            <v-col cols="12">
              Du <DateChip
                :date="course.start"
                @dateUpdated="updateCourseDates($event, index, 'start')"
              ></DateChip> au <DateChip
                :date="course.end"
                @dateUpdated="updateCourseDates($event, index, 'end')"
              ></DateChip>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                id="tutor"
                v-model="course.tutor"
                :items="tutors"
                :item-text="(v => v.user.displayName)"
                :item-value="(v => v.user._id)"
                outlined
                dense
                label="Tuteur"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-btn
            outlined
            class="white--text mt-5"
            color="primary"
            label
            @click="courseSelectDialog = true"
          >
            Selectionner formations
          </v-btn>
          <CourseSelectDialog
            :dialog="courseSelectDialog"
            @closeCourseSelectDialog="courseSelectDialog =false"
            @selectedCourses="updateContactCourses"
          ></CourseSelectDialog>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        :disabled="false"
        color="primary"
        @click="$emit('coursesSelected', courses)"
      >
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import CourseSelectDialog from '@/components/CourseSelectDialog.vue'
import DateChip from '@/components/DateChip.vue'

export default {
  components: {
    CourseSelectDialog,
    DateChip,
  },
  data() {
    return {
      courseSelectDialog: false,
      tutors: this.$store.getters.getTutors,
      courses: [],
    }
  },
  watch: {
    courses(val) {
      if (val) {
        this.$emit('courseUpdated', val)
      }
    },
  },
  methods: {
    updateContactCourses(payload) {
      const newCoursesIDs = payload.map(course => course._id)
      const existingCoursesIDs = this.courses.map(courses => courses.course._id)
      this.courses = this.courses.filter(course => newCoursesIDs.includes(course.course._id))
      payload.forEach(course => {
        if (!existingCoursesIDs.includes(course._id)) {
          this.courses.push({
            start: new Date(),
            end: new Date(),
            course,
          })
        }
      })
    },
    updateCourseDates(payload, index, time) {
      this.courses[index][time] = new Date(payload)
    },
  },

}
</script>

<style>

</style>
