<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="menuDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
      >
        <strong>
          {{ new Date(date) | date('dd MMMM yyyy') }}
        </strong>
      </v-chip>
    </template>
    <v-sheet>
      <v-text-field
        v-model="menuDate"
        type="date"
        class="mx-3 text-center"
      >
      </v-text-field>
      <v-date-picker
        v-model="menuDate"
        locale="fr"
        no-title
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Annuler
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="updateDate(menuDate)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  props: {
    date: {
      type: Date,
      default: new Date(),
    },
  },
  data() {
    return {
      menuDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
    }
  },
  watch: {
    date(val) {
      this.menuDate = new Date(val).toISOString().substr(0, 10)
    },
  },
  methods: {
    updateDate(menuDate) {
      this.$refs.menu.save(menuDate)
      this.$emit('dateUpdated', menuDate)
    },
  },
}
</script>

<style>

</style>
