<template>
  <v-form>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Récapitulatif</h3>
          <p>Valider les informations saisies</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <p>
                Vous avez créé un nouveau contact
                <v-chip
                  color="primary"
                  @click.prevent="$router.push({ name: 'contact-single', params: { id: contact._id } })"
                >
                  {{ contact.firstName }} {{ contact.lastName }}
                </v-chip>
              </p>
              <p v-if="businessContact">
                dans l'entreprise <v-chip
                  outlined
                  color="primary"
                  @click.prevent="$router.push({ name: 'business-contact-single', params: { id: businessContact._id } })"
                >
                  {{ businessContact.name }}
                </v-chip>
              </p>
              <p v-if="contact.courses">
                avec <b>{{ contact.courses.length }}</b> formation{{ contact.courses.length > 0 ? 's': '' }}
                <v-chip
                  v-for="(course, index) in contact.courses"
                  :key="index"
                >
                  {{ course.course.name }}
                </v-chip>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        :disabled="false"
        color="primary"
        outlined
        @click.prevent="$router.push({ name: 'contact-single', params: { id: contact._id } })"
      >
        Voir le profil
      </v-btn>
      <v-btn
        :disabled="false"
        outlined
        color="primary"
        @click.prevent="$router.go($router.currentRoute)"
      >
        Créer un autre
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>

export default {
  components: {

  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    businessContact: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },

  methods: {
  },

}
</script>

<style>

</style>
