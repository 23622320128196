<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      @click:outside="$emit('closeCourseSelectDialog')"
    >
      <v-card>
        <v-card-title>
          Selection de formation(s)
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          v-if="courses.length > 0"
          class="pb-3"
        >
          <v-combobox
            v-model="selectedCourses"
            label="Selectionner des formations"
            multiple
            chips
            :items="courses"
            :item-text="item => `${item.name} (${item.duration / 60} h)`"
            item-value="_id"
          >
          </v-combobox>
        </v-card-text>
        <v-card-text
          v-else
          class="d-flex justify-center flex-column"
        >
          <p class="text-center">
            Vous n'avez pas encore de formations.
          </p>
          <v-btn
            color="success"
            @click="$router.push({name: 'course-create'})"
          >
            Créer une formation
          </v-btn>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between mt-5">
          <v-btn
            color="primary"
            text
            @click="$router.push({name: 'course-create'})"
          >
            Nouvelle formation
          </v-btn>
          <v-btn
            outlined
            color="info"
            @click="$emit('closeCourseSelectDialog')"
          >
            Annuler
          </v-btn>
          <v-btn
            outlined
            class="white--text"
            color="success"
            label
            :disabled="selectedCourses.length < 1"
            @click="emitSelectionAndCloseDialog"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    preselectedCourses: {
      type: Array,
      default: () => [],

    },
  },
  data() {
    return {
      courses: [],
      selectedCourses: [],
    }
  },
  watch: {
    dialog(val) {
      if (val && this.courses.length < 1) {
        this.fetchData()
      }
    },
    preselectedCourses(val) {
      if (val) {
        this.selectedCourses = val
      }
    },
  },

  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/course/all`)
        .then(res => {
          this.courses = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
    emitSelectionAndCloseDialog() {
      this.$emit('selectedCourses', this.selectedCourses)
      this.$emit('closeCourseSelectDialog')
    },
  },
}
</script>

<style>

</style>
