<template>
  <v-card
    :key="componentKey"
    class="overflow-hidden mt-5"
  >
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon

        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Nouveau Contact
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiInformation }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div
        v-show="show"
      >
        <v-stepper v-model="contactCreationStep">
          <v-stepper-header>
            <v-stepper-step
              :complete="contactCreationStep > 1"
              step="1"
            >
              <v-btn
                v-if="Object.keys(contact).length !== 0"
                color="primary"
                text
                @click.prevent="contactCreationStep = 1"
              >
                1. Informations
              </v-btn>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="contactCreationStep > 2"
              step="2"
            >
              <v-btn
                v-if="courses.length !== 0"
                color="primary"
                text
              >
                2. Formations
              </v-btn>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              <v-btn
                v-if="contactCreationStep ===3"
                text
                color="primary"
              >
                3. Récapitulatif
              </v-btn>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <ContactFormPersonnalInfos
                :pre-selected-business-contact="preSelectedBusinessContact"
                @contactAddedToBusinessContact="contactAddedToBusinessContact"
                @contactCreated="contactCreated"
              ></ContactFormPersonnalInfos>
            </v-stepper-content>

            <v-stepper-content step="2">
              <ContactFormCoursesInfos @coursesSelected="coursesSelected"></ContactFormCoursesInfos>
            </v-stepper-content>

            <v-stepper-content step="3">
              <ContactFormFinalSummary
                :contact="contact"
                :business-contact="businessContact"
                @restart="componentKey+=1"
              ></ContactFormFinalSummary>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-divider></v-divider>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCalendar,
  mdiPhone,
  mdiMail,
} from '@mdi/js'

import ContactFormPersonnalInfos from './ContactFormPersonnalInfos.vue'
import ContactFormCoursesInfos from './ContactFormCoursesinfos.vue'
import ContactFormFinalSummary from './ContactFormFinalSummary.vue'

export default {
  components: {
    ContactFormPersonnalInfos,
    ContactFormCoursesInfos,
    ContactFormFinalSummary,
  },
  metaInfo: {
    title: 'Ajouter un contact',
    titleTemplate: '%s - Jimbo',
  },
  data() {
    return {
      preSelectedBusinessContact: this.$route.query.bc,
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCalendar,
        mdiPhone,
        mdiMail,
      },
      componentKey: 0,
      contactCreationStep: 1,
      professionalStatusList: [
        'Apprenti', 'Artisan', 'Artisan', 'Auteur', 'Commerçant', 'Exploitant agricole', 'Fonctionnaire', 'Intérimaire', 'interimaire', 'Intermittent du spectacle', 'Libéral', 'Pigiste', 'Saisonnier', 'Salarié', 'Travailleur indépendant', 'Travailleur intermittent', 'Retraité', 'Auto-entrepreneur', 'Recherche d\'emploi', 'Autre',
      ],
      courseSelectDialog: false,
      contact: {},
      courses: [],
      businessContact: {},
      show: true,
    }
  },
  methods: {
    contactAddedToBusinessContact(payload) {
      this.businessContact = payload
    },
    contactCreated(payload) {
      this.contact = payload
      this.contactCreationStep = 2
    },
    coursesSelected(payload) {
      this.courses = payload
      this.$http.put(`${process.env.VUE_APP_API_URL}/contact/update-courses/${this.contact._id}`, this.courses)
        .then(res => {
          this.contact = res.data
          this.contactCreationStep = 3
        })
        .catch(err => console.log(err))
        .finally()
    },

  },

}
</script>

<style>

</style>
